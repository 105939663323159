import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { PageTypes } from "@sparefoot/segment-react";

import { getNextSearchResultsPage } from "store/modules/search";
import { searchPageSelector } from "store/selectors/SearchPage";

import { Page, Section, Container } from "components/core/Page";
import { H1, Paragraph } from "components/core/Typography";
import { ScrollToTop } from "components/core/ScrollToTop";
import { HowItWorks } from "components/search/HowItWorks";
import { Breadcrumbs } from "components/search/Breadcrumbs";
import { FiltersSidebar, FiltersModal } from "components/search/SearchFilters";
import { SearchBar } from "components/search/SearchBar";
import SisterPages from "components/search/SearchLandingContent/SisterPages";
import NearbyCities from "components/search/SearchLandingContent/NearbyCities";

import { FILTER_GROUPS } from "config/filters";
import useSearchFilters from "hooks/search/useSearchFilters";
import { decodeQueryStringValue } from "utils/search/buildSearchLink";

import Row from "components/core/Page/Row";
import Column from "components/core/Page/Column";
import { BannerWithIcon } from "components/core/BannerWithIcon";
import { SearchResults } from "components/search/SearchResults";
import { Button } from "components/core/Button";
import { LoadingBoxes, Alert } from "components/core/Icons";
import { HTMLContent } from "components/core/HTMLContent";
import CLPHero from "components/clpHero/CLPHero";

import { Heading } from "components/storages/Common";

import { Schema } from "@sparefoot/react-commons";

import SearchPageContext from "./SearchPageContext";

import "./SearchPage.scss";

export default function SearchPage() {
	const {
		cityUrl,
		meta,
		pageText,
		pageType,
		headline,
		location,
		stateUrl,
		currentPage,
		hasMorePages,
		totalListings,
		nearbyCities,
		amenityPages,
		facilityCards,
		staticMapUrl
	} = useSelector(searchPageSelector);

	const dispatch = useDispatch();
	const { state, preferredPlaceName } = location;
	const { search } = useLocation();
	let queryParams = decodeQueryStringValue(search);

	const [showFilterModal, setShowFilterModal] = useState(false);
	const [isFiltering, setIsFiltering] = useState(false);
	const [isLoadingResults, setIsLoadingResults] = useState(false);

	useEffect(() => {
		setIsLoadingResults(false);
	}, [currentPage]);

	const {
		handleFilterChange,
		searchFilters,
		applySearch,
		selectedSort,
		handleSortChange
	} = useSearchFilters(
		[FILTER_GROUPS.UNIT_SIZE, FILTER_GROUPS.AMENITIES],
		queryParams
	);

	const searchPageContext = {
		// Modal State
		showFilterModal,
		setShowFilterModal,
		// Is Filtering State
		setIsFiltering,
		isFiltering,
		// Search Page Meta
		location,
		queryParams,
		// Search Filter State
		searchFilters,
		handleFilterChange,
		applySearch,
		// Sort
		selectedSort,
		handleSortChange,
		totalListings,
		pageType
	};

	const loadMore = () => {
		setIsLoadingResults(true);
		if (pageType !== PageTypes.SEARCH) {
			queryParams = {
				...queryParams,
				location: `${preferredPlaceName}, ${state}`,
				city: preferredPlaceName,
				state
			};
		}
		dispatch(
			getNextSearchResultsPage({ ...queryParams, page: currentPage })
		);
	};

	const showSearchLandingContent =
		(nearbyCities && nearbyCities.length > 0) || amenityPages;

	const ClpHeroContent = {
		heading: `Find Cheap Storage Units in ${location.city}, ${location.state}`,
		description: "Enter your ZIP Code to locate nearby storage facilities"
	};
	const isCLP = pageType === PageTypes.CITY_LANDING;
	const currentFacilityCount = facilityCards.length;
	const clpMapSchema = {
		"@context": "https://schema.org/",
		"@type": "Map",
		name: "Storage.com",
		url: staticMapUrl
	};
	return (
		<Page
			className="search-page"
			title={meta.title}
			pageType={pageType}
			description={meta.description}
			canonical={meta.canonical}
		>
			<SearchPageContext.Provider value={{ ...searchPageContext }}>
				{isCLP && <CLPHero {...ClpHeroContent} />}
				<div id="searchPage">
					<Container>
						{location.city === "Los Angeles" && (
							<BannerWithIcon
								icon={<Alert />}
								text="Important Alert"
								theme="alert"
								iconSize="fill"
								marginTop
							>
								<Paragraph
									size="small"
									weight="medium"
									last
								>
									Due to the fires in Los Angeles, CA area,
									some facilities may not have units available
									at this time. Please call (844) 611-1659 for
									assistance.
								</Paragraph>
							</BannerWithIcon>
						)}
						<Row
							className="page-layout"
							gutter="xlarge"
						>
							{/* SIDEBAR */}
							<Column
								className="sidebar"
								medium={4}
								mediumLarge={3}
								gutter="xlarge"
							>
								<FiltersSidebar />
							</Column>

							{/* MAIN AREA */}
							<Column
								className="main"
								small={12}
								medium={8}
								mediumLarge={9}
								gutter="xlarge"
							>
								{preferredPlaceName && !isCLP && (
									<Section
										flush
										className="title-section"
									>
										<H1
											size="xlarge"
											mediumSize="large"
											weight="bold"
										>
											{`${headline} ${preferredPlaceName}, ${state}`}
										</H1>
									</Section>
								)}

								{!isCLP && (
									<HowItWorks
										steps={[
											"Select your unit",
											"Reserve for free",
											"Visit your facility",
											"Move in"
										]}
									/>
								)}

								<Schema schema={clpMapSchema} />

								<SearchResults
									facilityCards={facilityCards}
									currentPage={currentPage}
								/>

								{hasMorePages && (
									<Button
										theme={
											isLoadingResults
												? "tertiary"
												: "secondary"
										}
										segmentLabel="facility search load more button"
										onClick={loadMore}
										className="load-more-button"
									>
										{isLoadingResults ? (
											<LoadingBoxes
												size="huge"
												primaryColor="white"
												secondaryColor="gray"
											/>
										) : (
											`Load ${
												currentFacilityCount >
												totalListings - 20
													? totalListings -
													  currentFacilityCount
													: 20
											} More`
										)}
									</Button>
								)}
								<SearchBar
									buttonText="Search"
									id="bottom-search-bar"
									label="Enter ZIP code..."
									theme="lightBlue"
									segmentCategory="SearchBar_Bottom_tray"
								>
									<Heading
										size="large"
										content="Update Your Storage Search"
										level="2"
									/>
									<Paragraph
										last
										size="small"
									>
										Find storage units near you
									</Paragraph>
								</SearchBar>

								{/* SEO Content */}
								{pageText && (
									<HTMLContent
										className="seoContent"
										content={pageText}
									/>
								)}

								{showSearchLandingContent && (
									<Section className="search-landing-content">
										{amenityPages && (
											<SisterPages
												header={`${preferredPlaceName} Storage by Amenity`}
												cityName={preferredPlaceName}
												links={amenityPages}
											/>
										)}
										{nearbyCities?.length > 0 && (
											<NearbyCities
												nearbyCities={nearbyCities}
											/>
										)}
									</Section>
								)}
								<Breadcrumbs
									location={location}
									cityUrl={cityUrl}
									stateUrl={stateUrl}
								/>
							</Column>
						</Row>
					</Container>
				</div>
				<FiltersModal />
			</SearchPageContext.Provider>
			<ScrollToTop />
		</Page>
	);
}
