import React from "react";
import FireAlertSVG from "assets/icons/fire-alert.inline.svg";

import Icon from "./Icon";

const Alert = ({ ...rest }) => (
	<Icon
		name="alert"
		alt="alert"
		{...rest}
	>
		<FireAlertSVG />
	</Icon>
);

export default Alert;
