import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import { Paragraph } from "components/core/Typography/Typography";

import "./BannerWithIcon.scss";

export default function BannerWithIcon({
	icon,
	text,
	className,
	textSize,
	textWeight,
	theme,
	fixed,
	marginTop,
	iconSize,
	children
}) {
	return (
		<div
			className={classnames(
				className,
				"banner-with-icon",
				`banner-${theme}`,
				{ "top-margin": marginTop },
				{ "banner-fixed": fixed },
				{ "banner-cta": !!children }
			)}
		>
			{icon && React.cloneElement(icon, { size: iconSize })}
			<div className="banner-content-wrapper">
				<Paragraph
					size={textSize}
					weight={textWeight}
					last
				>
					{text}
				</Paragraph>
				{children && <div className="banner-contents">{children}</div>}
			</div>
		</div>
	);
}

BannerWithIcon.defaultProps = {
	theme: "orange",
	textSize: "small",
	textWeight: "semiBold",
	iconSize: "xlarge"
};

BannerWithIcon.propTypes = {
	icon: PropTypes.node.isRequired,
	text: PropTypes.string.isRequired,
	textSize: PropTypes.string,
	textWeight: PropTypes.string,
	fixed: PropTypes.bool,
	theme: PropTypes.string,
	children: PropTypes.object,
	marginTop: PropTypes.bool,
	iconSize: PropTypes.string,
	className: PropTypes.string
};
